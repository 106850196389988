.ant-page-header-heading-title {
  color: white;
}
.ant-page-header-heading-sub-title {
  color: white;
}
.ant-page-header-heading-extra {
  color: white;
}

.ant-divider-horizontal.ant-divider-with-text::before, .ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid rgba(255,255,255,.5);
}

.ant-tag {
  border: none;
}
.ant-tabs-tab  {
  padding: 12px 10px;
}
.ant-tabs-content-holder {
  height: 100%;
}
.ant-tabs-content {
  height: 100%;
}
.ant-tabs-content-holder {
  margin: 0
}
.ant-tabs > .ant-tabs-nav {
  margin: 0 !important;
}
.ant-divider-horizontal.ant-divider-with-text::before, 
.ant-divider-horizontal.ant-divider-with-text::after {
  border-top-color: #d7d7d7 !important;
}
.ant-card .ant-card-body{
  height: 100%;
}

.ant-card.no-padding .ant-card-body {
  padding: 0;
}